import React from 'react';

export const NavBarData = [
    
    {
        title:"Home",
        link:"start",
        cName:'nav-text'
    },
    
    {
        title:"About",
        link:"about",
        cName:'nav-text'
    },
    {
        title:"Projects",
        link:"project",
        cName:'nav-text'
    },
    {
        title:"Contact",
        link:"contact",
        cName:'nav-text'
    }
];
