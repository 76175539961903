import React from 'react';
import { Link } from 'react-router-dom';
import './Start.css';
function start() {
    return (
        <div className = 'start'>
            <div className = 'start-desc'>
                <h1 className = 'start-header'>
                    Hi! I'm Huihao
                </h1>
                <p>
                    A software engineer with passion.
                </p>
            </div>
        </div>
    );
}

export default start;